import styles from './ContainerBlock.module.css';

function ContainerBlock(props){

    return (
        <div className={`${styles.container} ${styles[props.customClass]}`}>
            {props.children}
        </div>
    )

}

export default ContainerBlock