import { useEffect, useState } from 'react';
import Container from './Container';
import styles from './Portfolios.module.css';
import { Link } from 'react-router-dom';

function Portfolios(){

    const [projects, setProjects] = useState([]);

    useEffect( () => {
        fetch( 'https://ngengenharia.com.br/api/portfolio.php?limit=8', { 
            method: 'GET',
            headers: {
                'Content-Type': 'apllication/json'
            }
        })
        .then(response => response.json())
        .then(responseData => setProjects(responseData))
    },[]);

    return(
        <section className={styles.portfolio}>
            <Container>
                <div className={styles.grid_portfolio}>
                    <div>
                        <h2 className='title'>Portfólio</h2>
                    </div>
                    {projects.map( (project) => (
                        <div key={project.id}>
                            <img width="370" height="240" src={`images/portfolio/${project.link}/${project.main_image}-thumb.webp`} alt={project.title} />
                            <div className={styles.box_portolio_description}>
                                <h4>{project.title}</h4>
                                <Link to={`/portfolios/${project.link}`}>Ver mais</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
            <Container>
                <Link to='/portfolios' className='btn btn-round-right ml-auto mr-10'><span>Ver todos os projetos</span></Link>
            </Container>
        </section>
    )
}

export default Portfolios