import styles from "./Footer.module.css";
import Container from "./Container";
import { MdEmail } from "react-icons/md";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaHeart,
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import logo from "../../images/logo-ng-engenharia-colorida.png";

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <section className={styles.modules}>
        <Container>
          <div className={styles.company_text}>
            <img src={logo} alt="NG | Engenharia" width="200" height="45" />
            <p>Serviços de qualidade com eficiência.</p>
            <p>
              Com a NG Engenharia a sua obra acontece! Entre em contato e
              solicite o seu orçamento.
            </p>
          </div>
          <div className={styles.contacts}>
            <h3>Contatos</h3>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/hEtat3gd5FyZWZMA7"
                >
                  <FaMapMarkerAlt /> SCIA Quadra 14 Conjunto 04 Lote 08
                  <br />
                  Guará/DF CEP 71.250-120
                </a>
              </li>
              <li>
                <a href="tel:6133412646">
                  <FaPhoneAlt /> (61) 3341-2646
                </a>
              </li>
              <li>
                <a href="mailto:ng@ngengenharia.com.br">
                  <MdEmail /> ng@ngengenharia.com.br
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.social}>
            <h3>Siga-nos</h3>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  href="https://www.facebook.com/people/Ng-Engenharia/61559002567158/"
                  aria-label="Facebook"
                >
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  href="https://www.instagram.com/ngengenhariaeconstrucoes/"
                  aria-label="Instagram"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  href="https://youtube.com.br"
                  aria-label="Youtube"
                >
                  <FaYoutube />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  href="https://www.linkedin.com/company/ngengenhariaeconstrucoes/"
                  aria-label="Linkedin"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  href="https://x.com/ngengenharia"
                  aria-label="Twitter"
                >
                  <FaXTwitter />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                  href="https://wa.me/556133412646"
                  aria-label="Whatsapp"
                >
                  <FaWhatsapp />
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section className={styles.rights}>
        <Container>
          <div>NG Engenharia © {year} - Todos os Direitos Reservados</div>
          <div>
            Feito com <FaHeart /> por{" "}
            <a
              href="https://boaimagem.org/"
              title="Boa Imagem | Agência de Publicidade Marketing Digital Brasília DF"
              target="_blank"
            >
              BoaImagem
            </a>
          </div>
        </Container>
      </section>
    </footer>
  );
}

export default Footer;
