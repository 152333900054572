import { useEffect } from "react";
import Container from "../layout/Container";
import Service from "../layout/Services";
import Breadcrumbs from "../layout/Breadcrumbs";
import H1 from "../layout/H1";

function AboutUs() {
  useEffect(() => {
    document.title = "Quem Somos – NG | Engenharia";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Desde 2001, a NG Engenharia é referência em construção civil. Nascemos em Jataí/GO e nossa sede está em Brasília. Atuamos em diversas regiões como: Centro-Oeste, Sudeste, Norte e Nordeste do Brasil."
      );
  }, []);

  return (
    <section className="about-us">
      <H1>Quem Somos</H1>
      <Breadcrumbs />
      <Container>
        <p className="text-center">
          Desde 2001, a NG Engenharia é referência em construção civil. Nascemos
          em Jataí/GO e nossa sede está em Brasília. Atuamos em diversas regiões
          como: Centro-Oeste, Sudeste, Norte e Nordeste do Brasil, oferecendo
          uma ampla gama de serviços especializados que atendem diversas áreas
          da construção civil.
        </p>
      </Container>
      <Container>
        <h3 className="title">Nossa experiência</h3>
        <div>
          <p>
            Trabalhamos com projetos de grande porte. Somos especialistas em
            infraestrutura rodoviária, com ampla experiência na implantação e
            restauração de rodovias.
          </p>

          <p>
            Realizamos obras de infraestrutura urbana, incluindo pavimentação de
            vias, execução de drenagem pluvial e instalação de sistemas de
            esgotamento sanitário. Contamos com uma equipe de urbanização para
            atuar com projetos de construção de ciclovias, praças e calçadas, e
            em obras de artes especiais, como pontes e passarelas.
          </p>
        </div>
      </Container>
      <Container>
        <h3 className="title">Inovação e Qualidade</h3>
        <div>
          <p>
            Na NG Engenharia, a excelência é nosso compromisso. Combinamos
            técnicas de ponta e uma equipe de profissionais experientes para
            entregar projetos personalizados que superam expectativas e geram
            resultados concretos.
          </p>
        </div>
      </Container>
      <Container>
        <h3 className="title">Parcerias e Colaboração</h3>
        <div>
          <p>
            Na nossa empresa, as parcerias não são só negócios, são a chave para
            o sucesso!
          </p>

          <p>
            Acreditamos que a colaboração é a força que impulsiona o
            crescimento. Por isso, nossas relações são sólidas e buscamos sempre
            um ambiente no qual nossos profissionais e nossos clientes se sintam
            bem em compartilhar pontos de vistas que podem fazer o projeto ainda
            mais efetivo.
          </p>
        </div>
      </Container>
      <Service />
    </section>
  );
}

export default AboutUs;
