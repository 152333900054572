import styles from './Inputs.module.css';

function Textarea({name, label, value, handleOnChange}){
    return (
        <div className={styles.form_control}>
            <label htmlFor={name}>{label}</label>
            <textarea 
                id={name} 
                name={name} 
                value={value} 
                onChange={handleOnChange}
            ></textarea>
        </div>
    );
}
export default Textarea;