import { useEffect } from "react";
import Breadcrumbs from "../../layout/Breadcrumbs";
import H1 from "../../layout/H1";
import ContainerBlock from "../../layout/ContainerBlock";
import { Link } from "react-router-dom";

function NgCodeOfConduct() {
  useEffect(() => {
    document.title = "Código de Conduta da NG – NG | Engenharia";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "O Código de Ética e Conduta da NG reflete o compromisso de alcançarmos nossos objetivos empresariais com ética e transparência, desenvolvendo relacionamentos internos e externos baseados na integridade, preservando o meio ambiente e contribuindo para o bem-estar das comunidades onde estamos inseridos."
      );
  }, []);

  return (
    <>
      <H1>Código de Conduta da NG</H1>
      <Breadcrumbs />

      <ContainerBlock>
        <div className="main">
          <h3>O COMPROMISSO COM A INTEGRIDADE EMPRESARIAL É DE TODOS NÓS!</h3>
          <p>
            O Código de Ética e Conduta da NG reflete o compromisso de
            alcançarmos nossos objetivos empresariais com ética e transparência,
            desenvolvendo relacionamentos internos e externos baseados na
            integridade, preservando o meio ambiente e contribuindo para o
            bem-estar das comunidades onde estamos inseridos.
          </p>
          <p>
            O Código baseia-se nos Valores Empresariais da NG e nas melhores
            práticas de governança corporativa e contábil, tendo como premissa
            fundamental o pleno cumprimento das leis e dos regulamentos
            aplicáveis às operações da empresa, devendo ser plenamente observado
            por todos seus integrantes.
          </p>
          <p>
            A boa reputação e a credibilidade da NG são construídas por todas as
            suas pessoas, através de seus atos e atitudes dia após dia.
          </p>
          <p>
            Agradecemos a dedicação e o empenho de todos no pleno entendimento e
            observância desse Código e na proteção da integridade da empresa.
          </p>
          <p className="text-center">
            <strong>Luciano Neves Garcia</strong>
            <br />
            <strong>Presidente</strong>
          </p>

          <h3>SUMÁRIO</h3>
          <ol>
            <li>
              <a href="#pagina-01">
                O QUE É ESTE CÓDIGO DE CONDUTA EMPRESARIAL
              </a>
            </li>
            <li>
              <a href="#pagina-02">
                OBJETIVOS DO CÓDIGO DE CONDUTA EMPRESARIAL
              </a>
            </li>
            <li>
              <a href="#pagina-03">A QUEM ESTE CÓDIGO SE APLICA</a>
            </li>
            <li>
              <a href="#pagina-04">TERMOS E EXPRESSÕES EMPREGADOS</a>
            </li>
            <li>
              <a href="#pagina-05">VALORES DA NG ENGENHARIA</a>
            </li>
            <li>
              <a href="#pagina-06">
                ASPIRAÇÃO NG E COMPETÊNCIAS COMPORTAMENTAIS
              </a>
            </li>
            <li>
              <a href="#pagina-07">
                CÓDIGO DE CONDUTA EMPRESARIAL E COMISSÃO DE ÉTICA E INTEGRIDADE
              </a>
            </li>
            <li>
              <a href="#pagina-08">COMO AGIR EM DIFERENTES SITUAÇÕES</a>
            </li>
            <li>
              <a href="#pagina-09">
                CONDUTA GERAL DA NG EM RELAÇÃO AOS SEUS PROFISSIONAIS
              </a>
            </li>
            <li>
              <a href="#pagina-10">
                CONDUTA GERAL DA NG EM RELAÇÃO AOS SEUS FORNECEDORES
              </a>
            </li>
            <li>
              <a href="#pagina-11">
                CONDUTA EM RELAÇÃO AOS VEÍCULOS DE COMUNICAÇÃO
              </a>
            </li>
            <li>
              <a href="#pagina-12">
                CONDUTA EM RELAÇÃO À EMPRESA E AOS SEUS BENS
              </a>
            </li>
            <li>
              <a href="#pagina-13">
                CONDUTA EM RELAÇÃO À COMUNIDADE PRÓXIMA E AO AMBIENTE
              </a>
            </li>
            <li>
              <a href="#pagina-14">
                CONDUTA EM RELAÇÃO A ASSOCIAÇÕES PROFISSIONAIS, EMPRESARIAIS E
                ENTIDADES
              </a>
            </li>
            <li>
              <a href="#pagina-15">VOLUNTARIADO E RESPONSABILIDADE SOCIAL</a>
            </li>
            <li>
              <a href="#pagina-16">CONDUTA EM RELAÇÃO AO PODER PÚBLICO</a>
            </li>
            <li>
              <a href="#pagina-17">
                CONDUTA EM RELAÇÃO AO MERCADO DE INVESTIMENTO
              </a>
            </li>
            <li>
              <a href="#pagina-18">
                RESPONSABILIDADE PELO CÓDIGO DE CONDUTA E INTEGRIDADE
                EMPRESARIAL
              </a>
            </li>
            <li>
              <a href="#pagina-19">CANAIS DE COMUNICAÇÃO</a>
            </li>
            <li>
              <a href="#pagina-20">MEDIDAS DISCIPLINARES</a>
            </li>
          </ol>

          <div id="pagina-01" className="page_code">
            <h3>1. O QUE É ESTE CÓDIGO DE CONDUTA EMPRESARIAL</h3>
            <p>
              A NG executa obras de grande escala e tem atuação regional e
              nacional no setor da Construção Civil.
              <br />
              As atitudes devem ser comuns, motivo pelo qual este Código de
              Conduta Empresarial reflete o modo como os profissionais de todas
              as atividades e setores da NG Engenharia agem em relação à
              sociedade, bem como os fornecedores devem agir.
            </p>
            <p>
              Todas as pessoas devem atuar de forma correta, íntegra e eficiente
              na busca de resultados, incorporando os valores expressos neste
              Código, obedecendo aos regulamentos e normas internas.
            </p>
          </div>

          <div id="pagina-02" className="page_code">
            <h3>2. OBJETIVOS DO CÓDIGO DE CONDUTA EMPRESARIAL</h3>
            <p>
              O Código de Conduta Empresarial foi preparado com os seguintes
              objetivos:
            </p>
            <ul>
              <li>
                Deixar claros os valores empresariais, para que todos os
                profissionais e fornecedores possam compreendê-los, respeitá-los
                e praticá-los;
              </li>
              <li>
                Servir de referência individual e coletiva para as atitudes e o
                comportamento de cada profissional e fornecedor;
              </li>
              <li>
                Contribuir para que esses valores sejam respeitados em todas as
                localidades e seus profissionais e fornecedores ajam de maneira
                correta, justa, respeitosa em relação à comunidade e ao meio
                ambiente.
              </li>
            </ul>
          </div>

          <div id="pagina-03" className="page_code">
            <h3>3. A QUEM ESTE CÓDIGO SE APLICA</h3>
            <p>
              O Código de Conduta Empresarial se aplica a todos os profissionais
              que trabalham na NG Engenharia e deve regular os relacionamentos
              que esses profissionais mantêm dentro e fora da empresa
              <br />
              com:
            </p>
            <ul>
              <li>demais profissionais da empresa ou da NG;</li>
              <li>
                fornecedores, clientes, bancos, outros parceiros e concorrentes;
                poder público, em todos os níveis;
              </li>
              <li>comunidade local e com a sociedade em geral.</li>
            </ul>
            <p>
              O Código de Conduta Empresarial se aplica também a todos os
              fornecedores, parceiros comerciais e pessoas físicas e jurídicas
              com as quais a NG se relaciona.
            </p>
          </div>

          <div id="pagina-04" className="page_code">
            <h3>4. TERMOS E EXPRESSÕES EMPREGADOS</h3>
            <p>
              <strong>NG, NG ENGENHARIA OU EMPRESA</strong>
              <br />
              Trata-se da NG Engenharia Ltda e as atividades que desenvolve.
            </p>
            <p>
              <strong>CONDUTA</strong>
              <br />
              Comportamento, procedimento moral.
            </p>
            <p>
              <strong>CÓDIGO</strong>
              <br />
              Conjunto de regras, leis ou normas.
            </p>
            <p>
              <strong>ÉTICA</strong>
              <br />
              Conjunto de costumes, hábitos e comportamentos.
            </p>
            <p>
              <strong>FORNECEDORES</strong>
              <br />
              São considerados fornecedores os parceiros comerciais, pessoas
              físicas e jurídicas com as quais a NG se relaciona.
            </p>
            <p>
              <strong>CÓDIGO DE CONDUTA EMPRESARIAL</strong>
              <br />
              Normas e comportamentos que regem a atuação da organização e
              definem o que ela espera de seus profissionais, e fornecedores e
              outros parceiros.
            </p>
            <p>
              <strong>PROFISSIONAIS</strong>
              <br />
              São todos os profissionais que trabalham na empresa,
              independentemente do cargo, função, atividade ou tempo de casa.
            </p>
            <p>
              <strong>VALORES</strong>
              <br />
              Expressam o compromisso permanente da empresa com a sociedade em
              geral, com as pessoas que nela trabalham e com ela se relacionam.
            </p>
          </div>

          <div id="pagina-05" className="page_code">
            <h3>5. VALORES DA NG ENGENHARIA</h3>
            <p>
              A NG segue valores permanentes, originários da sua história e de
              sua prática:
            </p>
            <p>
              <strong>RESPEITO ÀS PESSOAS E AO MEIO AMBIENTE</strong>
              <br />
              Agir sempre correta e justamente em relação aos profissionais,
              clientes, fornecedores, governos, comunidades locais e à sociedade
              em geral. Atuar com responsabilidade em relação ao meio ambiente.
            </p>
            <p>
              <strong>ATUAÇÃO RESPONSÁVEL</strong>
              <br />
              Atender ao estabelecido na legislação onde quer que atuemos,
              agindo de forma íntegra. Respeitar a diversidade de acordo com as
              normas universais de boa convivência humana, sem discriminação de
              raça, sexo, credo, religião, cargo, função etc.
            </p>
            <p>
              <strong>TRANSPARÊNCIA</strong>
              <br />
              Fornecer informações claras e abrangentes sobre atividades,
              realizações, políticas e desempenho da NG, de maneira sistemática
              e acessível.
            </p>
            <p>
              <strong>FOCO NO RESULTADO</strong>
              <br />
              Buscar sempre maximizar o desempenho da NG, como forma de garantir
              sua perenidade, seus investimentos e as condições adequadas aos
              profissionais.
            </p>
          </div>

          <div id="pagina-06" className="page_code">
            <h3>6. ASPIRAÇÃO NG E COMPETÊNCIAS COMPORTAMENTAIS</h3>
            <p>
              A atuação da NG e o comportamento esperado de seus profissionais e
              fornecedores são direcionados, respectivamente, pela Aspiração e
              Competências Comportamentais, amplamente divulgadas internamente e
              por meio do presente Código de Conduta.
            </p>
            <h4>6.1 ASPIRAÇÃO</h4>
            <p>
              Transformar o panorama urbano por meio de grandes construções com
              foco na geração de valor percebido clientes, profissionais e
              população;
            </p>
            <h4>6.2 COMPETÊNCIAS COMPORTAMENTAIS</h4>
            <p>
              <strong>PROATIVIDADE</strong>
              <br />
              Ser proativo e realizador, entendendo-se como parte fundamental da
              solução.
            </p>
            <p>
              <strong>COMPROMISSO COM O RESULTADO</strong>
              <br />
              Ser comprometido com a lucratividade do negócio, a satisfação dos
              clientes, os prazos e qualidade acordados e a austeridade com os
              custos.
            </p>
            <p>
              <strong>LIDERANÇA EFICAZ</strong>
              <br />
              Influenciar e mobilizar as pessoas para atingir os objetivos do
              negócio, inspirando e direcionando sua equipe, perseguindo a alta
              performance.
            </p>
            <p>
              <strong>EXCELÊNCIA NAS RELAÇÕES</strong>
              <br />
              Construir e manter relações pautadas em confiança, respeito às
              diferenças e transparência, assumindo suas responsabilidades e
              adotando uma comunicação clara e empática.
            </p>
            <p>
              <strong>ADAPTABILIDADE</strong>
              <br />
              Ser disponível, agir para incorporar novos conceitos e ser
              resiliente para lidar de forma construtiva com conflitos.
            </p>
            <p>
              <strong>SENSO DE RESPONSABILIDADE</strong>
              <br />
              Ser disciplinado, priorizar atividades com eficácia e assumir
              riscos calculados para atingir as metas, responsabilizando-se
              pelas consequências de suas decisões e comprometendo-se com a
              gestão de recursos.
            </p>
            <p>
              <strong>COOPERAÇÃO</strong>
              <br />
              Trabalhar de forma colaborativa e criativa, oferecer e aceitar
              ajuda e estimular a integração entre as áreas, reconhecendo a
              importância da contribuição de cada indivíduo para o todo.
            </p>
          </div>

          <div id="pagina-07" className="page_code">
            <h3>
              7. CÓDIGO DE CONDUTA EMPRESARIAL E COMISSÃO DE ÉTICA E INTEGRIDADE
            </h3>
            <p>
              O Código de Conduta Empresarial é um importante passo para a
              consolidação dos valores e da ética empresarial e foi estabelecido
              a partir da experiência e com base em ampla discussão entre
              diretor e profissionais.
            </p>
            <p>
              A Comissão de Ética e Integridade, composta por representantes de
              áreas da empresa e coordenada pela área de Engenharia (vide
              documentos específicos do conselho / comissão de ética), é
              responsável por aperfeiçoar este trabalho e está aberto à
              sugestões de todos os profissionais e fornecedores.
            </p>
          </div>

          <div id="pagina-08" className="page_code">
            <h3>8. COMO AGIR EM DIFERENTES SITUAÇÕES</h3>
            <p>
              O Código indica o que a organização espera de cada profissional e
              fornecedor nas diferentes situações que pode enfrentar no trabalho
              ou mesmo fora dele.
            </p>
            <p>
              Sempre que estiver indeciso sobre como agir, consulte este Código
              de Conduta Empresarial. Em caso de dúvidas, consulte seu superior
              imediato, o Conselho de COMPLIANCE da empresa, ou entre em contato
              por meio do canal de integridade, pelo site{" "}
              <Link to="/">www.ngengenharia.com.br</Link>, email:{" "}
              <a href="mailto:canaldeintegridade@ngengenharia.com.br">
                canaldeintegridade@ngengenharia.com.br
              </a>{" "}
              ou pelo WhatsApp: (61) 3341-2646.
            </p>
          </div>

          <div id="pagina-09" className="page_code">
            <h3>9. CONDUTA GERAL DA NG EM RELAÇÃO AOS SEUS PROFISSIONAIS</h3>
            <p>
              A empresa acredita que a diversidade de seus profissionais é um
              dos fatores principais para a manutenção de seu sucesso, sua
              permanência e seu crescimento. Por essa razão, busca recrutar e
              manter pessoas eficientes e talentosas e investe continuamente no
              seu desenvolvimento.
            </p>
            <p>
              A empresa está comprometida com a igualdade de oportunidades de
              trabalho para todos, independentemente de raça, sexo, religião,
              crenças ou nacionalidade.
            </p>
            <p>
              Não são toleradas práticas discriminatórias contra qualquer
              profissional ou candidato a emprego. Dessa forma, a NG somente
              declinará de um candidato a qualquer tipo de cargo se realmente
              houver fortes indícios de que a contratação possui características
              e/ou interesses alheios àqueles unicamente ligados à sua
              competência e expertise profissional, bem como à iminente
              contribuição ao crescimento da NG.
            </p>
            <p>
              É parte da política empresarial da NG promover o desenvolvimento
              de seus profissionais, valorizar e reconhecer as pessoas com base
              nos resultados alcançados e assegurar que o trabalho na empresa
              seja um espaço de criação e realização.
            </p>
            <p>
              A organização incentiva o respeito e a colaboração entre os
              profissionais, de modo a criar um ambiente interno favorável para
              o crescimento dos negócios e a perenização da NG.
            </p>
            <p>
              Assédio moral ou sexual são práticas inadmissíveis num ambiente de
              trabalho respeitoso e digno e não serão tolerados.
              <br />
              Atos de intimidação, ofensa ou agressão praticados por
              profissionais, nas dependências da empresa ou no exercício da
              função, seja contra colegas de trabalho ou pessoas que não estejam
              ligadas diretamente à empresa (clientes, fornecedores,
              consumidores, autoridades, integrantes da comunidade etc.) serão
              punidos de acordo com a legislação e os regulamentos internos.
            </p>
            <p>
              Se você for vítima de qualquer constrangimento desse tipo ou
              souber de alguém que tenha passado por essa situação, informe seu
              superior imediato ou a área de Recursos Humanos ou a Comissão de
              Ética e Integridade.
            </p>
            <p>
              O porte de drogas ou armas não é permitido de forma alguma no
              ambiente de trabalho, sendo considerado infração grave, sujeita às
              sanções trabalhistas e penais.
            </p>
            <h4>9.1 DIREITOS FUNDAMENTAIS DO PROFISSIONAL</h4>
            <p>
              Todo profissional, independentemente de sua função, posição, cargo
              ou salário, será tratado com respeito e atenção, sendo a ele
              oferecidas condições para o desenvolvimento pessoal e profissional
              dentro da realidade e condições de competitividade de sua unidade
              de trabalho.
            </p>
            <p>
              As condições de saúde e segurança no trabalho devem ser objetos de
              permanente atenção e devem assegurar aos profissionais o menor
              risco possível no desempenho de suas funções.
            </p>
            <p>
              Para que isso ocorra, todos devem acatar as normas gerais de saúde
              preventiva e segurança e participar dos treinamentos e das
              atividades de orientação.
            </p>
            <p>Segurança é um valor fundamental, que se reflete na frase:</p>
            <p>“faremos de forma segura ou não faremos”.</p>
            <h4>9.2 CONDUTA PESSOAL DO PROFISSIONAL</h4>
            <p>
              Devem ser evitadas atividades e relações pessoais que conflitem
              com os interesses da NG.
            </p>
            <p>
              parentes e amigos para vagas existentes na NG é prática
              tradicional. Cabe às áreas responsáveis decidir pela seleção e
              contratação, não sendo admissíveis pressões para influenciar a
              admissão, promoção ou demissão pelos profissionais ligados.
            </p>
            <p>Não será permitida a subordinação direta de parentes.</p>
            <h4>9.3 CONDUTA EM RELAÇÃO A BRINDES</h4>
            <p>
              A aceitação de brindes e presentes deverá ter valor limitado a R$
              150,00 e o profissional deverá comunicar ao seu gestor. Os casos
              que não se enquadram nesta definição deverão ser recusados. Na
              impossibilidade da recusa ou devolução, os brindes e/ou presentes
              fora dos limites de valor ora estabelecido serão colocados à
              disposição da Comissão de Ética e Integridade para destinação
              final.
            </p>
            <p>
              Convites para eventos e viagens devem ser comunicados à Comissão
              de Ética e Integridade através do respectivo gestor. A avaliação
              será individual e a aprovação de despesas de cada profissional
              deverá ser feita, no mínimo, por seu superior.
            </p>
            <p>
              Está vetada a oferta de brindes pela NG, com exceção aos brindes
              de clientes e dos brindes motivacionais para campanhas de call
              center, limitados a R$ 150,00.
            </p>
            <h4>
              9.4 CONDUTA EM RELAÇÃO AO MERCADO – CLIENTES, FORNECEDORES,BANCOS,
              PARCEIROS E CONCORRENTES
            </h4>
            <p>
              <strong>ATENDIMENTO AO CLIENTE</strong>
              <br />
              Além de assegurar a qualidade dos produtos e serviços que oferece,
              a NG tem o compromisso de atender aos clientes e consumidores
              sempre com eficiência, rapidez, educação e transparência.
            </p>
            <p>
              Quando o cliente ou consumidor não puder ser atendido, isso deve
              ser dito claramente, explicando-se as razões de forma evidente e
              respeitosa.
            </p>
            <p>
              <strong>QUALIDADE E CONFORMIDADE</strong>
              <br />
              Somente serão oferecidos produtos ou serviços que estejam em
              conformidade com a legislação e as normas setoriais. Na
              eventualidade de se constatar alguma não conformidade, deverão ser
              tomadas medidas
              <br />
              explicativas ou compensatórias.
            </p>
            <p>
              <strong>CONFIDENCIALIDADE</strong>
              <br />A NG, assim como seus profissionais, tem como compromisso
              manter a confidencialidade de informações recebidas de clientes e
              parceiros de negócios. A confiança é uma das bases do
              relacionamento com o mercado.
            </p>
            <p>
              <strong>CONCORRÊNCIA E DIREITOS DO CONSUMIDOR</strong>
              <br />A NG entende que a lealdade na concorrência e o respeito aos
              direitos do consumidor são fatores que favorecem o desenvolvimento
              do mercado em bases sustentáveis. Nenhum cliente pode ser forçado
              a aceitar condições que contrariem essas regras para adquirir
              produtos ou serviços da empresa.
            </p>
            <p>
              <strong>CONCORRÊNCIA</strong>
              <br />A NG respeita seus concorrentes e busca superá-los de
              maneira saudável, ofertando produtos e soluções com relação de
              custo-benefício diferenciada a seus clientes. Não se admite,
              atitudes que possam configurar calúnia ou difamação dos
              concorrentes.
            </p>
            <p>
              <strong>COMPRAS E CONTRATOS</strong>
              <br />O relacionamento com fornecedores e outros parceiros deve
              ser sempre pautado pela busca da qualidade, adequada relação
              custo-benefício, confiabilidade técnica e financeira, integridade
              na condução da negociação, no que se refere à legislação, ao meio
              ambiente e aos direitos comerciais, sociais e contratuais. Com o
              objetivo de ampliar sua atuação sustentável, a NG recomenda que
              suas áreas de Compras e Suprimentos busquem formas permanentes de
              influenciar seus fornecedores de produtos, serviços e equipamentos
              a adotarem atuação responsável nas áreas social e ambiental, e
              para que sejam signatários deste Código de Conduta e Integridade
              Empresarial.
            </p>
          </div>

          <div id="pagina-10" className="page_code">
            <h3>10. CONDUTA GERAL DA NG EM RELAÇÃO AOS SEUS FORNECEDORES</h3>
            <p>
              A NG acredita na solidez do relacionamento com seus fornecedores.
              Para isso é fundamental que os fornecedores conheçam e atuem em
              conformidade com a legislação e com o presente Código, sendo
              responsável por disseminar, monitorar e assegurar o cumprimento
              deste em todas as suas atividades.
            </p>
            <p>
              <strong>INTEGRIDADE</strong>
              <br />
              Espera-se que todos os fornecedores demonstrem altos padrões
              éticos e mantenham a integridade em todas as interações com a NG e
              administração pública. Assim, toda e qualquer forma de corrupção,
              extorsão ou fraude é estritamente proibida.
            </p>
            <p>
              A NG não compactua com práticas comerciais coercitivas para obter
              vantagem ou qualquer tipo de assédio (moral, sexual, político e
              religioso).
            </p>
            <p>
              <strong>CONFIDENCIALIDADE</strong>
              <br />
              Segundo a ABNT, a “A informação é um ativo que, como qualquer
              outro ativo importante, é essencial para os negócios de uma
              organização e, consequentemente, necessita ser adequadamente
              protegida. […] A informação pode existir em diversas formas. Ela
              pode ser impressa ou escrita em papel, armazenada eletronicamente,
              transmitida pelo correio ou por meios eletrônicos, apresentada em
              filmes ou falada em conversas. Seja qual for à forma de
              apresentação ou o meio através do qual a informação é
              compartilhada ou armazenada, é recomendado que ela seja sempre
              protegida adequadamente.” A confiança é uma das bases do
              relacionamento da NG com seus fornecedores, dessa forma, espera-se
              que todos os dados, detalhes e informações que forem transmitidas
              pela NG sejam tratadas como confidenciais pelo fornecedor, devendo
              este último proteger a informação de ameaças para garantir a
              continuidade dos negócios e minimizar riscos à empresa.
            </p>
          </div>

          <div id="pagina-11" className="page_code">
            <h3>11. CONDUTA EM RELAÇÃO AOS VEÍCULOS DE COMUNICAÇÃO</h3>
            <p>
              A NG considera legítimo o direito da população de ser informada
              sobre assuntos de interesse público, mesmo quando relativos a uma
              empresa privada, e considera que é papel da imprensa obter e
              divulgar essas informações. Além disso, entende que a existência
              de imprensa livre, independente e imparcial contribui para o
              aperfeiçoamento do mercado, do Estado democrático e da cidadania.
              Por essa razão, a NG repudia a utilização do poder econômico para
              constranger a ação da imprensa ou para induzi-la a divulgar fatos
              inverídicos.
            </p>
            <p>
              A NG considera importante o papel da imprensa na formação da
              imagem da organização perante a opinião pública e procura fornecer
              informações ou atender às solicitações, quando são pertinentes,
              considerando o direito de não se manifestar em questões que
              contrariem seus interesses ou de manter sigilo sobre informações
              consideradas estratégicas.
            </p>
            <p>
              Apenas os profissionais e fornecedores indicados para exercer o
              papel de porta-vozes estão autorizados a falar em nome da empresa.
              Se você for procurado para dar informações, escrever artigos ou
              dar entrevistas e declarações em nome da empresa a algum veículo
              de comunicação, informe seu superior ou a Comissão / Conselho de
              Integridade da NG.
            </p>
          </div>

          <div id="pagina-12" className="page_code">
            <h3>12. CONDUTA EM RELAÇÃO À EMPRESA E AOS SEUS BENS</h3>
            <p>
              Cada profissional e fornecedor tem responsabilidade pelo correto
              uso e guarda dos bens e ativos da empresa que fazem parte de seu
              trabalho, direta ou indiretamente.
            </p>
            <p>
              O mesmo vale para os bens de clientes, fornecedores e parceiros
              usados na atividade da empresa. Esses bens e ativos não devem ser
              utilizados em benefício pessoal, exceto quando expressamente
              autorizado.
            </p>
            <p>
              Entre esses bens e ativos estão os imóveis, equipamentos,
              instalações, planos de negócios, informações técnicas e de
              mercado, programas de computador, modelos, papéis e documentos de
              trabalho e outros que fazem parte do patrimônio da empresa.
            </p>
            <p>
              A apropriação ou a utilização indevida de qualquer desses bens,
              incluindo sua cópia, venda ou distribuição a terceiros, são
              infrações graves, que podem acarretar sanções trabalhista, civil,
              criminal e/ou administrativa cabíveis.
            </p>
            <p>
              <strong>COMUNICAÇÃO INTERNA E EXTERNA</strong>
              <br />A utilização dos equipamentos e meios de comunicação da
              empresa (telefone, e-mail, internet e outros) para comunicados e
              contatos pessoais deve ser restrita ao necessário. A internet não
              pode ser utilizada para transmissão ou recepção de informações
              ofensivas, agressivas, pornográficas, sobre posicionamentos
              políticos, religiosos ou outros, de acordo com a regulamentação
              interna específica sobre.
            </p>
            <p>
              <strong>REGISTROS CONTÁBEIS E OUTROS</strong>
              <br />
              Os registros devem ser elaborados e acompanhados tanto pelos
              responsáveis diretos quanto por todos os gestores de áreas,
              obedecendo ao que determinam a legislação, as normas fiscais e as
              regras internas. Os lançamentos e registros ficam à disposição dos
              gestores, das áreas de controle e auditoria e das autoridades
              legais.
            </p>
            <p>
              <strong>INFORMAÇÕES PRIVILEGIADAS</strong>
              <br />O profissional e fornecedor que vier a ter conhecimento de
              informações que não são públicas tem o dever de mantê-las
              confidenciais, mesmo após deixar a empresa ou encerrar o vínculo
              contratual. O uso de informações privilegiadas em benefício
              pessoal ou de terceiros é crime, sujeito a sanções trabalhistas,
              civis e penais.
            </p>
            <p>
              <strong>PAPÉIS DE TRABALHO E DOCUMENTOS DA EMPRESA</strong>
              <br />
              Os papéis de trabalho, os relatórios, as correspondências e outros
              documentos usados na atividade de cada profissional e fornecedor
              são de propriedade da empresa e não podem ser levados ou copiados,
              quando ocorrer o desligamento do profissional ou encerramento do
              contrato.
            </p>
            <p>
              <strong>PASSAGENS AÉREAS</strong>
              <br />A compra de passagens aéreas necessárias às atividades da
              empresa serão solicitadas e cotadas pela empresa cadastrada para
              este fim, e não poderá ser emitida em nome de terceiros, que não
              estejam envolvidos diretamente na referida atividade, de forma
              justificada.
            </p>
          </div>

          <div id="pagina-13" className="page_code">
            <h3>13. CONDUTA EM RELAÇÃO À COMUNIDADE PRÓXIMA E AO AMBIENTE</h3>
            <p>
              A NG procura sempre conviver de forma harmoniosa com as
              comunidades onde suas unidades de negócios atuam, respeitando as
              pessoas, suas tradições, seus valores e o meio ambiente. Ao mesmo
              tempo, busca colaborar ativamente no desenvolvimento local, na
              melhoria da qualidade de vida e na redução dos problemas e das
              desigualdades sociais.
            </p>
            <p>
              <strong>TRABALHO INFANTIL</strong>
              <br />A NG não utiliza trabalho infantil e deve procurar se
              certificar de que seus fornecedores seguem o mesmo princípio. A NG
              apoia e realiza atividades que ajudam a desenvolver as crianças
              das comunidades onde atua e, na medida do possível, oferece
              estágios e programas de treinamento e aprendizagem para jovens.
            </p>
            <p>
              <strong>ATIVIDADES POLÍTICO-PARTIDÁRIAS</strong>
              <br />
              A NG não exerce atividades políticas, e cada profissional que
              deseje participar desse processo deve fazê-lo individualmente, sem
              envolver o nome ou os recursos da empresa. Nenhum profissional
              está autorizado a solicitar participação, apoio, financiamento ou
              envolvimento de outros profissionais ou unidades de negócios com
              qualquer candidato ou partido. Atividades
              <br />
              políticas realizadas por profissionais devem ocorrer fora do
              ambiente de trabalho e das horas de expediente.
            </p>
            <p>
              <strong>ATIVIDADES SINDICAIS</strong>
              <br />A NG busca manter com as entidades sindicais uma relação de
              respeito e não pratica qualquer tipo de discriminação aos
              profissionais sindicalizados.
            </p>
            <p>
              <strong>MEIO AMBIENTE</strong>
              <br />
              Todos os empreendimentos da empresa devem ser realizados em
              obediência à legislação e às normas ambientais, buscando
              adicionalmente a avaliação prévia de aspectos e impactos
              ambientais, o uso racional de recursos naturais, e a preservação
              do meio ambiente onde atuamos.
            </p>
            <p>
              <strong>EDUCAÇÃO, AÇÃO SOCIAL E VIZINHANÇA</strong>
              <br />
              Nosso compromisso com a sociedade está baseado no respeito pelas
              comunidades do entorno onde atuamos, buscando ainda colaborar no
              desenvolvimento de projetos educacionais e sociais, estimulando a
              superação de nossas deficiências e a incorporação de novas
              atitudes e de novos procedimentos. O relacionamento com a
              vizinhança é realizado através de
              <br />
              um canal específico que é parte do Programa de Boa Vizinhança,
              onde buscamos divulgar nossas ações, operações e ainda receber e
              responder a críticas ou sugestões de nossos vizinhos.
            </p>
            <p>
              <strong>ENTIDADES RELIGIOSAS</strong>
              <br />A NG procura manter o melhor relacionamento com entidades de
              cunho religioso, respeitando todos os credos e crenças, tanto de
              seus profissionais como das comunidades onde atua.
            </p>
          </div>

          <div id="pagina-14" className="page_code">
            <h3>
              14. CONDUTA EM RELAÇÃO A ASSOCIAÇÕES PROFISSIONAIS, EMPRESARIAIS E
              ENTIDADES
            </h3>
            <p>
              A NG tem como filosofia participar das entidades e associações
              representativas de seus setores de atuação, sempre e quando esta
              participação contribuir para o desenvolvimento do setor e não
              implicar em infrações às normas e princípios da livre
              concorrência. A forma de participação deve ser definida caso a
              caso pela Diretoria, levando-se em conta o perfil das associações
              locais.
            </p>
            <p>
              A participação ativa dos profissionais em entidades de caráter
              social, cultural ou beneficente que tenham reconhecimento público,
              feita em caráter individual, é vista como uma contribuição
              importante à sociedade e ao país, desde que não afete sua
              atividade e o trabalho regular na NG.
            </p>
          </div>

          <div id="pagina-15" className="page_code">
            <h3>15. VOLUNTARIADO E RESPONSABILIDADE SOCIAL</h3>
            <p>
              Essas atividades são consideradas parte da responsabilidade com a
              comunidade onde possui atuação, e devem ser estruturadas e
              organizadas de modo a obter o máximo de retorno social possível em
              relação aos recursos empregados e ao tempo dos profissionais
              envolvidos.
            </p>
          </div>

          <div id="pagina-16" className="page_code">
            <h3>16. CONDUTA EM RELAÇÃO AO PODER PÚBLICO</h3>
            <p>
              O relacionamento com autoridades, políticos e agentes públicos,
              deve ser pautado por atitudes profissionais e corretas. A NG
              repudia todo e qualquer ato lesivo contra o patrimônio público de
              qualquer país. É dever de todo e qualquer profissional da NG e de
              todos os fornecedores pautar suas atividades e relacionamentos com
              órgãos e agentes públicos dentro da mais estrita legalidade e
              moralidade.
            </p>
            <p>
              A NG não tolera práticas que envolvam qualquer vantagem indevida
              a/ou de agente público, fraude a contratos e/ou procedimentos
              licitatórios, manipulação do equilíbrio econômico-financeiro de
              contratos, impedimento ou obstrução de atividade investigativa ou
              fiscalizatória de órgãos públicos, sendo obrigação do profissional
              da NG e dos fornecedores, que tenha conhecimento de tais atitudes,
              reportá-las a seu superior imediato e/ou à Comissão de Ética e
              Integridade, ou ainda comunicar imediatamente a empresa por meio
              da Linha Ética e Integridade, pelo site{" "}
              <Link to="/">www.ngengenharia.com.br</Link>, email:{" "}
              <a href="mailto:canaldeintegridade@ngengenharia.com.br">
                canaldeintegridade@ngengenharia.com.br
              </a>{" "}
              ou pelo WhatsApp: (61) 3341-2646.
            </p>
            <p>
              A NG tem como filosofia o estrito cumprimento da legislação
              vigente e espera o mesmo comportamento de seus profissionais e
              fornecedores. Considera, entretanto, que é legítimo contestar
              medidas legais ou fiscais abusivas, discriminatórias ou
              incorretas, o que será feito por meio de ações administrativas
              e/ou judiciais nos poderes competentes.
            </p>
          </div>

          <div id="pagina-17" className="page_code">
            <h3>17. CONDUTA EM RELAÇÃO AO MERCADO DE INVESTIMENTO</h3>
            <p>
              Na hipótese da NG se envolver em qualquer negócio com investidor,
              este deve seguir os procedimentos estabelecidos pelos órgãos
              reguladores e fiscalizadores do mercado de investimentos.
            </p>
          </div>

          <div id="pagina-18" className="page_code">
            <h3>
              18. RESPONSABILIDADE PELO CÓDIGO DE CONDUTA E INTEGRIDADE
              EMPRESARIAL
            </h3>
            <p>
              A responsabilidade geral pelo Código de Conduta Empresarial da NG
              é da Comissão de Ética e Integridade.
            </p>
            <p>
              <strong>LIDERANÇAS</strong>
              <br />
              Cada líder de equipe é um representante da NG em relação aos
              profissionais que lidera e tem as obrigações abaixo descritas.
            </p>
            <p>
              Os fornecedores também deverão ter um representante com as
              seguintes obrigações:
            </p>
            <ul>
              <li>
                conhecer detalhadamente o Código, de modo a esclarecer as
                dúvidas de sua equipe. Não sendo possível isso, deve encaminhar
                as questões à Comissão de Ética e Integridade;
              </li>
              <li>
                adotar comportamentos e atitudes que correspondam ao
                estabelecido no Código, de modo a servir de exemplo;
              </li>
              <li>
                divulgar os valores e as definições do Código para sua equipe,
                seus parceiros, clientes, fornecedores e outros segmentos
                sociais com quem mantém contato, orientando- os sobre os
                procedimentos previstos;
              </li>
              <li>
                identificar infrações ao Código e atuar de modo a corrigi-las e
                eliminá-las, levando os casos ao conhecimento da Comissão de
                Ética e Integridade para informação e eventuais ações
                adicionais.
              </li>
            </ul>
            <p>
              <strong>RESPONSABILIDADE INDIVIDUAL</strong>
              <br />
              Cada profissional da NG e cada fornecedor deve zelar pelo
              cumprimento do Código de Conduta Empresarial e informar seu
              superior imediato, a Comissão de Ética e Integridade, quando
              souber de alguma violação às normas.
            </p>
            <p>
              Ninguém será punido ou retaliado por informações de boa-fé sobre
              suspeitas de um comportamento inadequado ou que seja contrário ao
              Código.
            </p>
            <p>
              Situações especiais devem sempre ser encaminhadas ao conhecimento
              da Comissão de Ética e Integridade.
            </p>
          </div>

          <div id="pagina-19" className="page_code">
            <h3>19. CANAIS DE COMUNICAÇÃO</h3>
            <p>
              Todos os colaboradores, fornecedores e parceiros da NG devem
              conhecer e cumprir com os preceitos deste Código para o bom
              desempenho das suas atividades e relacionamento profissional.
            </p>
            <p>
              É extremamente importante que a NG saiba quais são os problemas
              enfrentados e as possíveis violações relacionados a este Código.
              Somente assim poderá adotar as medidas necessárias para solucionar
              as situações indesejadas e evitar que futuras violações venham a
              ocorrer.
            </p>
            <p>
              Todos os colaboradores têm o direito e o dever de comunicar ao
              Comitê de Compliance sobre as violações a este Código, bem como às
              normas e políticas internas e à legislação vigente. Para isso,
              está disponibilizando o <strong>Canal de Integridade</strong>,
              canal de comunicação por meio do qual qualquer pessoa pode
              apresentar denúncias de violação e esclarecer dúvidas sobre o
              conteúdo e aplicação deste Código.
            </p>
            <p>
              O <strong>Canal de Integridade</strong> da NG oferece a qualquer
              interessado, incluindo colaboradores, fornecedores e parceiros, os
              seguintes meios de comunicação:
            </p>
            <p>
              • Mensagens eletrônicas pela internet acessando o endereço:{" "}
              <Link to="/canal-de-integridade">
                http://ngengenharia.com.br/canal-de-integridade/
              </Link>
              <br />• Mensagens eletrônicas para:{" "}
              <a href="mailto:canaldeintegridade@ngengenharia.com.br">
                canaldeintegridade@ngengenharia.com.br
              </a>
              <br />• Mensagens eletrônicas via WhatsApp: (61) 3341-2646
            </p>
            <p>
              Ao fazer uma comunicação ao Comitê de Compliance, seu anonimato
              será preservado.
            </p>
            <p>
              As comunicações feitas serão analisadas de forma imparcial pelo
              Comitê de Compliance, que foi criado especialmente para garantir a
              aplicação deste Código.
            </p>
            <p>
              O Comitê ficará responsável por responder às dúvidas apresentadas
              e coordenar as investigações das denúncias. Tudo será feito de
              forma confidencial de modo a não expor os integrantes ou a NG
              perante terceiros.
            </p>
            <p>
              <strong>
                Nenhum colaborador sofrerá qualquer retaliação em razão de
                denúncias feitas de boa-fé
              </strong>
              . Por outro lado, constitui violação a este Código a apresentação
              de falsas acusações ou informações incorretas e inverídicas. A NG
              incentiva apenas as comunicações feitas de boa-fé, com
              responsabilidade e compromisso ético.
            </p>
            <p>
              Além de comunicar violação e desvios de condutas previstas neste
              Código e na legislação vigente, o colaborador também poderá
              utilizar o <strong>Canal de Integridade NG para sugestões</strong>
              , recomendações ou reclamações sobre a atuação do Comitê de
              Compliance da NG. O sigilo e anonimato sempre serão preservados.
            </p>
            <p>
              A NG valoriza a colaboração de seus integrantes para a solução de
              irregularidades cometidas dentro da empresa. Desse modo, são
              incentivadas as consultas para que os integrantes não se vejam
              envolvidos em infrações a este Código.
            </p>
          </div>

          <div id="pagina-20" className="page_code">
            <h3>20. MEDIDAS DISCIPLINARES</h3>
            <p>
              O pleno entendimento e atendimento das diretrizes deste código é
              condição fundamental para a permanência do profissional e
              fornecedor nos quadros da NG.
            </p>
            <p>
              O descumprimento dos princípios e compromissos expressos neste
              Código de Conduta será tratado como assunto de extrema gravidade,
              e poderá ensejar:
            </p>
            <p>
              <strong>PARA SEUS PROFISSIONAIS</strong>
              <br />
              Adoção de medidas disciplinares, que poderão incluir advertência,
              suspensão, cancelamento da remuneração variável e até mesmo
              demissão por justa causa, de acordo com o nível e a natureza da
              infração, bem como de acordo com as leis aplicáveis, sem prejuízo
              da responsabilização civil, criminal e/ou administrativa cabíveis.
            </p>
            <p>
              <strong>PARA SEUS FORNECEDORES</strong>
              <br />
              Extinção da relação comercial, sem prejuízo da responsabilização
              civil, criminal e/ou administrativa cabíveis
            </p>
          </div>
        </div>
      </ContainerBlock>
    </>
  );
}

export default NgCodeOfConduct;
