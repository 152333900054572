import styles from './Inputs.module.css';

function Select({label, name, options, handleOnChange, value}){
    
    return (
        <div className={styles.form_control}>
            <label htmlFor={name}>{label}</label>
            <select name={name} id={name} onChange={handleOnChange} value={value || ''}>
                <option>Selecione uma opção</option>

                {options.map( (option, index) => (
                    <option value={option.name} key={index}>
                        {option.name}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default Select;