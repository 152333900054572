import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Grid from "../layout/Grid";
import styles from '../layout/Services.module.css'
import Breadcrumbs from "../layout/Breadcrumbs";
import H1 from "../layout/H1";
import ContainerBlock from "../layout/ContainerBlock";

function Services(){

    useEffect(() => {
        document.title = "Nossos Serviços – NG | Engenharia"
        document.querySelector('meta[name="description"]').setAttribute('content', 'Com estratégia, tecnologia e equipe especializada, atuamos nos mais diversos projetos. Alinhamos inovação com segurança e levamos as melhores infraestruturas a diversos locais do país.');
     }, []);

    const [data, setData] = useState([]);

    useEffect( () => {
        fetch('https://ngengenharia.com.br/api/services.php', {
            method: "GET",
            headers: {
                "Content-Type": 'application/json'
            }
        })
        .then(response => response.json())
        .then(dataResponse => {
            setData(dataResponse);
        });
    }, [])

    // Função para remover tags HTML
    function stripHtmlTags(str) {
        if (!str) {
            return '';
        }
        return str.replace(/<\/?[^>]+(>|$)/g, '');
    }

    const items = data.map( (item) => (
        <div className="item" data-value={item.id}>
            <img src={`images/servicos/${item.image}`} alt={item.title} />
            <h4>{item.title}</h4>
            <p>{stripHtmlTags(item.text)}</p>
            <Link to={`/servicos/${item.link}`} className='btn'><span>Saiba mais</span></Link>
        </div>
    ))

    return (
        <section className="services">
            <H1>Nossos serviços</H1>
            <Breadcrumbs />
            <ContainerBlock>
                <p className="text-center">Com estratégia, tecnologia e equipe especializada, atuamos nos mais diversos projetos. Alinhamos inovação com segurança e levamos as melhores infraestruturas a diversos locais do país.</p>

                <p className="text-center">Veja em nosso <Link to='/portfolios'>portfólio</Link> os ramos de projetos que atuamos e como funciona o processo de contratação dos nossos serviços.</p>
            </ContainerBlock>

            <div className={styles.services}>
                <Grid customClass='colunms_3'>
                    {items}
                </Grid>
            </div>

        </section>
    )
}

export default Services;