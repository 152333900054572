import React, { useState, useEffect } from "react";
import styles from "./BackgroundVideo.module.css";
import myVideo from "../../assets/ng-engenharia-7.mp4";

const BackgroundVideo = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [fade, setFade] = useState("");
  let indexTexts = 0;

  const texts = [
    {
      title: "Experiência",
      description:
        "Desde 2001 entregamos aos nossos clientes agilidade, segurança e comprometimento em forma de projetos bem executados",
    },
    {
      title: "Eficiência",
      description:
        "Nossos projetos carregam a habilidade de cada especialista do nosso time, pensando em detalhes, máxima eficiência e resultados surpreendentes",
    },
    {
      title: "Inovação",
      description:
        "Trabalhamos com alta tecnologia, desde o processo de criação do projeto até a entrega",
    },
  ];

  function changeTexts() {
    setTitle(texts[indexTexts].title);
    setText(texts[indexTexts].description);
  }

  useEffect(() => {
    setInterval(() => {
      setFade("fade");
      indexTexts = indexTexts >= texts.length - 1 ? 0 : indexTexts + 1;
      setTimeout(() => {
        changeTexts();
      }, 500);
      setTimeout(() => {
        setFade("");
      }, 500);
    }, 4000);
    changeTexts();
  }, [texts.length]);

  return (
    <section className={styles.main}>
      <div className={styles.overlay}></div>
      <video autoPlay muted loop id="video">
        <source src={myVideo} type="video/mp4" />
      </video>
      <div className={`${styles.content} ${styles[fade]}`}>
        <h1>{title}</h1>
        <p>{text}</p>
      </div>
    </section>
  );
};

export default BackgroundVideo;
