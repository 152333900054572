import { useEffect } from "react";
import Container from "../../layout/Container";
import Breadcrumbs from "../../layout/Breadcrumbs";
import H1 from "../../layout/H1";
import ContainerBlock from "../../layout/ContainerBlock";

function NgBusinessIntegrityPolicy(){

    useEffect(() => {
        document.title = "Política de integridade empresarial da NG – NG | Engenharia"
        document.querySelector('meta[name="description"]').setAttribute('content', 'A NG ENGENHARIA, tem como política de Compliance a terminante proibição de quaisquer atividades que venham a gerar corrupção, através do atendimento legal e de quaisquer outros requisitos.');
    }, []);   

    return (
        <>
        <H1>Política de integridade empresarial da NG</H1>
        <Breadcrumbs />
        
        <div className="main">
        <Container>            
            <h1 className="title">
                <span>
                    Política de integridade empresarial<br/>
                    <i>Compliance</i>
                </span>
            </h1>

            <p>A NG ENGENHARIA, tem como política de <i>Compliance</i> a terminante proibição de quaisquer atividades que venham a gerar corrupção, através do atendimento legal e de quaisquer outros requisitos federais, regionais e locais em busca incessante pela melhoria contínua em suas atividades, processos e desenvolvimentos pessoais para atingir aos objetivos do negócio – construções de grande porte. Com foco na busca pelos relacionamentos de boa fé, dentre eles com colaboradores, clientes, provedores externos, órgãos e instituições públicas e privadas, entre outras partes interessadas, estando quaisquer atividades irregulares sob pena de consequências trabalhistas, criminais, civis. Para a gestão estarão devidamente estabelecidas na NG ENGENHARIA, em documentos apropriados, a autoridade e responsabilidade das figuras do Conselho e um profissional diretamente responsável pelo <i>Compliance</i> empresarial.</p>
        </Container>
        <ContainerBlock>
            <p className="text-center">
                <strong>NG ENGENHARIA E CONSTRUÇÕES LTDA.</strong><br/>
                LUCIANO NEVES GARCIA<br/>
                PRESIDENTE
            </p>
        </ContainerBlock>
        </div>
        </>
    );
}

export default NgBusinessIntegrityPolicy;