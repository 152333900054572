import Container from "../layout/Container";
import styles from "./Home.module.css";

import cliente1 from "../../images/clientes/der-df.webp";
import cliente2 from "../../images/clientes/governo-do-para.webp";
import cliente3 from "../../images/clientes/novacap.webp";
import cliente4 from "../../images/clientes/parque-do-lago.webp";
import cliente5 from "../../images/clientes/prefeitura-araguaina.webp";
import cliente6 from "../../images/clientes/prefeitura-de-macapa.webp";
import cliente7 from "../../images/clientes/prefeitura-municipal-de-sinop.webp";
import cliente8 from "../../images/clientes/residencial-canarinho.webp";
import cliente9 from "../../images/clientes/secretaria-de-obras-e-infraestrutura-GDF.webp";
import cliente10 from "../../images/clientes/sinop-energia.webp";
import cliente11 from "../../images/clientes/governo-de-alagoas.webp";
import cliente12 from "../../images/clientes/governo-do-estado-amapa.webp";
import cliente13 from "../../images/clientes/agesul.webp";

import Service from "../layout/Services";
import Portfolios from "../layout/Portfolios";
import BackgroundVideo from "../layout/BackgroundVideo";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function Home() {
  useEffect(() => {
    document.title = "NG | Engenharia";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Agilidade e segurança fazem parte do nosso vocabulário ao executar uma obra! Com atitude de alto-nível profissional, entregamos trabalhos com integridade que suprem totalmente as necessidades dos contratantes. Conheça algumas de nossas obras através do nosso portfólio."
      );
  }, []);

  return (
    <>
      <BackgroundVideo />

      <section className={styles.about_us}>
        <Container>
          <h2 className="title">Quem somos</h2>
          <p>
            Desde 2001, a NG Engenharia é referência em construção civil.
            Nascemos em Jataí/GO e nossa sede está em Brasília. Atuamos em
            diversas regiões como: Centro-Oeste, Sudeste, Norte e Nordeste do
            Brasil, oferecendo uma ampla gama de serviços especializados que
            atendem diversas áreas da construção civil.
            <Link to={"/quem-somos"}>Saiba mais</Link>
          </p>
        </Container>
      </section>

      <Service />
      <Portfolios />

      <section className={styles.clients}>
        <Container>
          <h2 className="title">Principais Clientes</h2>
          <div className={styles.grid}>
            <img width="182" height="94" alt="Logo da DER-DF" src={cliente1} />
            <img
              width="182"
              height="94"
              alt="Logo do Governo do Pará"
              src={cliente2}
            />
            <img width="182" height="94" alt="Logo da Novacap" src={cliente3} />
            <img
              width="182"
              height="94"
              alt="Logo do Parque do lago"
              src={cliente4}
            />
            <img
              width="182"
              height="94"
              alt="Logo da Prefeitura Araguaína"
              src={cliente5}
            />
            <img
              width="182"
              height="94"
              alt="Logo da Prefeitura de Macapá"
              src={cliente6}
            />
          </div>
        </Container>
        <Container>
          <div id="gridSecond" className={styles.grid}>
            <img
              width="182"
              height="94"
              alt="Logo da Prefeitura Munincipal de Sinop"
              src={cliente7}
            />
            <img
              width="182"
              height="94"
              alt="Logo do Residêncial Canarinho"
              src={cliente8}
            />
            <img
              width="182"
              height="94"
              alt="Logo da Secretaria de Obras e Infraestrutura"
              src={cliente9}
            />
            <img
              width="182"
              height="94"
              alt="Logo da SINOP Energia"
              src={cliente10}
            />
            <img
              width="182"
              height="94"
              alt="Logo do Governo de Alagoas"
              src={cliente11}
            />
            <img
              width="182"
              height="94"
              alt="Logo do Governo do Estado do Amapá"
              src={cliente12}
            />
            <img width="182" height="94" alt="Logo da Agesul" src={cliente13} />
          </div>
        </Container>
      </section>
    </>
  );
}

export default Home;
