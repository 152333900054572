import { useState, useEffect } from "react";
import ContainerBlock from "../layout/ContainerBlock";
import Container from "../layout/Container";
import styles from '../layout/Portfolios.module.css';
import { Link } from "react-router-dom";
import Breadcrumbs from "../layout/Breadcrumbs";
import H1 from "../layout/H1";


function Portfolios(){

    useEffect(() => {
        document.title = "Portfólio – NG | Engenharia"
        document.querySelector('meta[name="description"]').setAttribute('content', 'Nossa prioridade é a qualidade do serviço. Somos referência na construção civil, utilizando alta tecnologia e garantindo a durabilidade de nossos projetos.');
     }, []);

    const [projects, setProjects] = useState([]);

    useEffect( () => {
        fetch( 'https://ngengenharia.com.br/api/portfolio.php', { 
            method: 'GET',
            headers: {
                'Content-Type': 'apllication/json'
            }
        })
        .then(response => response.json())
        .then(responseData => setProjects(responseData))
    },[]);

    return (
        <section className={styles.portfoliosPage}>
            <H1>Portfólio</H1>
            <Breadcrumbs />

            <ContainerBlock>
                <h3 className="text-center title-big font-light">Conheça <b>nosso portfólio</b></h3>
                <p className="text-center">Nossa prioridade é a qualidade do serviço. Somos referência na construção civil, utilizando alta tecnologia e garantindo a durabilidade de nossos projetos. Desenvolvemos cada obra para atender às necessidades específicas de nossos clientes, sempre observando rigorosamente as normas técnicas vigentes. Nosso compromisso com a excelência assegura resultados superiores em cada empreendimento.</p>                
            </ContainerBlock>  

            <div className="main">
                <Container className={styles.paddingZero}>
                    <div id="portfoliosItems" className={styles.grid_portfolio}>
                        {projects.map( (project) => (
                            <div key={project.id}>
                                <img width="370" height="240" src={`images/portfolio/${project.link}/${project.main_image}-thumb.webp`} alt={project.title} />
                                <div className={styles.box_portolio_description}>
                                    <h4>{project.title}</h4>
                                    <Link to={`/portfolios/${project.link}`}>Ver mais</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>  
            </div>       
        </section>
    )
}

export default Portfolios;