import { useEffect, useState } from "react";
import Container from "../layout/Container";
import Inputs from "../layout/Inputs";
import Textarea from "../layout/Textarea";
import Breadcrumbs from "../layout/Breadcrumbs";
import H1 from "../layout/H1";
import MessageForm from "../layout/MessageForm";

function Contact(){

    useEffect(() => {
        document.title = "Contato – NG | Engenharia"
        document.querySelector('meta[name="description"]').setAttribute('content', 'Construa o futuro com a NG Engenharia. Faça seu projeto decolar! Preencha o formulário abaixo e um de nossos especialistas entrará em contato para entender como podemos ajudar.');
    }, []);

    const [formValues, setFormValues] = useState([]);
    const [sendSuccess, setSendSuccess] = useState(null);

    function handleChange(e){
        setFormValues({...formValues, [e.target.name]: e.target.value});
    }

    const submitForm = async (e) => {
        console.log(formValues);
        e.preventDefault();
        try {
            const res = await fetch('https://ngengenharia.com.br/api/send-contact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues)
            });
            const data = await res.json();
            if (!res.ok) {
                setSendSuccess(false);
                throw new Error('Erro ao enviar o formulário');
            }
            setSendSuccess(data);
        }catch(error){
            throw new Error('Erro ao enviar o formulário');
        }
    }    

    return (
        <section className="contactPage">
            <H1>Entre em contato</H1>
            <Breadcrumbs />

            <div className="main">
                <Container customClass='paddingZero'>
                    <h1 className="title">Fale Conosco</h1>
                    <p><b>Construa o futuro com a NG Engenharia.</b><br/>
                    Preencha o formulário abaixo e um de nossos especialistas entrará em contato para entender como podemos ajudar.<br/>
                    Ng Engenharia - Qualidade em cada projeto.</p>
                </Container>
                
                <Container>
                    {sendSuccess === null && (
                    <form onSubmit={submitForm} className="form width50percent">
                        <Inputs 
                            type="text"
                            name="name"
                            value={formValues.name ? formValues.name : ''}
                            label="Seu nome:*"
                            handleOnChange={handleChange}
                        />

                        <Inputs
                            type="email"
                            name="email"
                            value={formValues.email ? formValues.email : ''}
                            label="Seu e-mail:*"
                            handleOnChange={handleChange}
                        />

                        <Inputs
                            type="text"
                            name="subject"
                            value={formValues.subject ? formValues.subject : ''}
                            label="Assunto:*"
                            handleOnChange={handleChange}
                        />

                        <Textarea
                            name="message"
                            value={formValues.message ? formValues.message : ''}
                            label="Sua Mensagem:"
                            handleOnChange={handleChange}
                        />

                        <input className="btn btn-round-right ml-auto" type="submit" value="Enviar"/>
                    </form>
                    )}

                    {sendSuccess !== null && (
                    <div className="width50percent">
                        <MessageForm success={sendSuccess}/>
                    </div>
                    )}

                    <div className="width50percent">
                        <div>
                            <h3>Informações para Contato</h3>
                            <p>(61) 3341-2646<br/>
                            ng@ngengenharia.com.br</p>
                        </div>

                        <div>
                            <h3>Endereço</h3>
                            <p>SCIA Quadra 14 Conjunto 04 Lote 08 Guará/DF<br/>
                            CEP 71.250-120</p>
                        </div>

                        <div className="mapEmbed">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d61429.39705665234!2d-47.973908!3d-15.786158!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a31418537e3e3%3A0x60c9b1592bbccc39!2sNG%20Engenharia!5e0!3m2!1spt-BR!2sbr!4v1719347930443!5m2!1spt-BR!2sbr" width="100%" height="250" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>

                </Container>
            </div>
        </section>
    )
}

export default Contact;