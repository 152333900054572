import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./components/pages/Home";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import AboutUs from "./components/pages/AboutUs";
import Services from "./components/pages/Services";
import Service from "./components/pages/Service";
import Portfolios from "./components/pages/Portfolios";
import Portfolio from "./components/pages/Portfolio";
import Contact from "./components/pages/Contact";
import IntegrityChannel from "./components/pages/integrity-channel/IntegrityChannel";
import NgBusinessIntegrityPolicy from "./components/pages/integrity-channel/NgBusinessIntegrityPolicy";
import NgCodeOfConduct from "./components/pages/integrity-channel/NgCodeOfConduct";
import WorkWithUs from "./components/pages/WorkWithUs";

function App() {
  /* <Route path="/catalog">
    <Route path=":subpage" element={<Element/>} />
 </Route> */
  return (
    <div className="App">
      <Router>
        <Navbar />
        <main>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="/quem-somos" element={<AboutUs />} />
              <Route path="/servicos">
                <Route index element={<Services />} />
                <Route path=":link" element={<Service />} />
              </Route>
              <Route path="/portfolios">
                <Route index element={<Portfolios />} />
                <Route path=":link" element={<Portfolio />} />
              </Route>
              <Route path="/canal-de-integridade">
                <Route index element={<IntegrityChannel />} />
                <Route
                  path="politica-de-integridade-empresarial-da-ng"
                  element={<NgBusinessIntegrityPolicy />}
                />
                <Route
                  path="codigo-de-conduta-da-ng"
                  element={<NgCodeOfConduct />}
                />
              </Route>
              <Route path="/contato">
                <Route index element={<Contact />} />
                <Route path="trabalhe-conosco" element={<WorkWithUs />} />
              </Route>
            </Route>
          </Routes>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
