import styles from './Inputs.module.css';

function Inputs({type, name, label, value, handleOnChange}){
    return (
        <div className={styles.form_control}>
            <label htmlFor={name}>{label}</label>
            <input 
                id={name} 
                type={type} 
                name={name} 
                value={value} 
                onChange={handleOnChange}
            />
        </div>
    );
}
export default Inputs;