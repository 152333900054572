import { useState } from "react";
import styles from './GalleryPortfolio.module.css';
import { MdClose, MdNavigateNext, MdNavigateBefore } from "react-icons/md";

function GalleryPortfolio({items, title, link}){

    const [imgLink, setImgLink] = useState(0);
    const [openedLightbox, setOpenedLightbox] = useState(false);
    const [next, setNext] = useState();
    const [prev, setPrev] = useState();

    function openLightbox(e){
        e.preventDefault();
        let urlImgIndex = parseInt(e.currentTarget.getAttribute('data-img'));
        let urlImg = link + '-0' + urlImgIndex;
        setImgLink(urlImg);
        setOpenedLightbox(true);
        changePrevNext(urlImgIndex);
    }

    function closeLightbox(){
        setOpenedLightbox(false);
    }

    function changePrevNext(urlImgIndex){
        setPrev( (parseInt(urlImgIndex) === 1) ? items.length : urlImgIndex - 1);
        setNext( (urlImgIndex === items.length) ? 1 : urlImgIndex + 1);
    }
    
    return (
        <>
        <div className={styles.GalleryPortfolioTumbs}>
            {items.map( (item, index) => (
                <a className={styles.galleryPortfolio} href={item.small} onClick={openLightbox} key={index+1} data-img={index+1}>
                    <img width="320" height="240" src={item.small} alt={title} data={index+1} />
                </a>
            ))}
        </div>

        {(openedLightbox && (
            <div id="lightbox" className={`${styles.lightbox} ${openedLightbox ? styles.lighboxOpen : ''}`}>
                <div className={styles.box}>
                    <div className={styles.close}>
                        <MdClose onClick={closeLightbox} />
                    </div>
                    <div className={styles.centerBox}>
                        <MdNavigateBefore onClick={openLightbox} data-img={prev}/>
                        <picture>
                            <source media="(max-width: 320px)" srcSet={`${imgLink}-320x.webp`} />
                            <source media="(max-width: 480px)" srcSet={`${imgLink}-480x.webp`} />
                            <source media="(max-width: 768px)" srcSet={`${imgLink}-768x.webp`} />
                            <source media="(max-width: 1024px)" srcSet={`${imgLink}-1024x.webp`} />
                            <source media="(max-width: 1280px)" srcSet={`${imgLink}-1280x.webp`} />
                            <img src={`${imgLink}-1280x.webp`} alt={title} />
                        </picture>
                        <MdNavigateNext onClick={openLightbox} data-img={next}/>
                    </div>
                </div>
            </div>
        ))}
        </>
    )

}

export default GalleryPortfolio;