import { Link, NavLink } from "react-router-dom";
import logo from "../../images/logo-ng-engenharia-branca.png";
import styles from "./Navbar.module.css";
import { useState } from "react";
import Container from "./Container";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  return (
    <header className={styles.header}>
      <Container customClass="paddingZero">
        <div className={styles.logo}>
          <Link to="/">
            <img src={logo} alt="NG | Engenharia" width="200" height="45" />
          </Link>
        </div>
        <div className={styles.menu}>
          <nav>
            <div
              className={`${styles.menu_toggle} ${
                menuOpen ? styles.menuOpen : styles.menuClose
              }`}
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul
              className={`${styles.first_menu} ${
                menuOpen ? styles.menuOpenItens : styles.menuCloseItens
              }`}
              onClick={toggleMenu}
            >
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/quem-somos">Quem Somos</NavLink>
              </li>
              <li>
                <NavLink to="/servicos">Serviços</NavLink>
                <ul>
                  <li>
                    <NavLink to="/servicos/barragens">Barragens</NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/condominios-e-loteamentos">
                      Condomínios e loteamentos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/contencoes">Contenções</NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/drenagem-pluvial">
                      Drenagem Pluvial
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/irrigacao">Irrigação</NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/obras-de-arte-especiais">
                      Obras de Arte Especiais
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/pavimentacao">Pavimentação</NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/recuperacao-ambiental">
                      Recuperação Ambiental
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/restauracao-manutencao-e-conservacao-de-rodovias">
                      Restauração, manutenção e conservação de rodovias
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/saneamento">Saneamento</NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/terraplenagem">
                      Terraplenagem
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/servicos/urbanizacao">Urbanização</NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to="/portfolios">Portfólio</NavLink>
              </li>
              <li>
                <NavLink to="/canal-de-integridade">
                  Canal de Integridade
                </NavLink>
                <ul>
                  <li>
                    <NavLink to="/canal-de-integridade/politica-de-integridade-empresarial-da-ng">
                      Política de integridade empresarial da NG
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/canal-de-integridade/codigo-de-conduta-da-ng">
                      Código de Conduta da NG
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to="/contato">Contato</NavLink>
                <ul>
                  <li>
                    <NavLink to="/contato/trabalhe-conosco">
                      Trabalhe Conosco
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </header>
  );
}

export default Navbar;
