import styles from './Grid.module.css'

function Grid({children, customClass}){

    return (
        <div className={`${styles.grid} ${styles[customClass]}`}>
            {children}
        </div>
    )

}

export default Grid;