import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Breadcrumbs.module.css'
import ContainerBlock from './ContainerBlock';

const Breadcrumbs = () => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const activeLinks = document.querySelectorAll('a.active');
    const breadcrumbItems = Array.from(activeLinks).map((link) => ({
      text: link.textContent,
      href: link.getAttribute('href'),
    }));
    setBreadcrumbs(breadcrumbItems);
  }, [location]);

  return (
      <nav className={styles.breadcrumbs}>
        <ContainerBlock>
        <ul >
          <li>
            <Link to="/">Home</Link>
          </li>
          {breadcrumbs.map((breadcrumb, index) => (
            <li key={index}>
              <Link to={breadcrumb.href}>{breadcrumb.text}</Link>
            </li>
          ))}
        </ul>
        </ContainerBlock>
      </nav>
  );
};

export default Breadcrumbs;
