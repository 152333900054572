import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Container from '../layout/Container';
import styles from './Services.module.css';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

function Service(){

    const [data, setData] = useState([]);

    useEffect( () => {
        fetch('https://ngengenharia.com.br/api/services.php', {
            method: "GET",
            headers: {
                "Content-Type": 'application/json'
            }
        })
        .then(response => response.json())
        .then(dataResponse => {
            setData(dataResponse);
        });
    }, [])

    // Função para remover tags HTML
    function stripHtmlTags(str) {
        if (!str) {
            return '';
        }
        return str.replace(/<\/?[^>]+(>|$)/g, '');
    }

    const items = data.map( (item) => (
        <div className="item" data-value={item.id}>
            <img src={`images/servicos/${item.image}`} alt={item.title} width="450" height="241" />
            <h4>{item.title}</h4>
            <p>{stripHtmlTags(item.text)}</p>
            <Link to={`/servicos/${item.link}`} className='btn'><span>Saiba mais</span></Link>
        </div>
    ))

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };

    return (
        <>
        <section className={styles.services}>
            <Container customClass="block">
                <h3>Serviços</h3>
                <div className="carousel_container">
                    <AliceCarousel
                        mouseTracking
                        disableDotsControls
                        items={items}
                        responsive={responsive}
                        controlsStrategy="responsive"
                        autoPlay={true}
                        autoPlayInterval={5000}
                        infinite={true}
                        keyboardNavigation={true}
                        renderPrevButton={() => {
                        return <button className="carousel_button prev-button" aria-label="Serviço anterior"><IoIosArrowBack /></button>;
                        }}
                        renderNextButton={() => {
                        return <button className="carousel_button next-button" aria-label="Próximo serviço"><IoIosArrowForward /></button>;
                        }}
                    />
                    </div>
            </Container>
        </section>
        </>
    );
}

export default Service