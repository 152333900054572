import styles from './MessageForm.module.css';

const MessageForm = ({success}) => {
    return (
        <>
        { success && (
        <div className={styles.boxSuccess}>
            <p>Formulário enviado com sucesso!</p>
        </div>
        )}

        { !success && (
        <div className={styles.boxError}>
            <p>Não foi possível enviar o formulário!</p>
        </div>
        )}
        </>
    )
}

export default MessageForm;