import ContainerBlock from './ContainerBlock';
import styles from './H1.module.css';

function H1({children}){
    return (
        <div className={styles.h1}>
            <ContainerBlock>
                <h1>
                    {children}
                </h1>
            </ContainerBlock>
        </div>
    );
}
export default H1;